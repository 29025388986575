import { makeStyles } from '@material-ui/styles';
import logo from '../images/logo.svg';
import appName from '../images/app-name.png';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  REACT_APP_KEYCLOAK_LOGOUT_URL
} from '../config.js';
import { useEffect, useState } from 'react';
import packageJson from '../../package.json';

import {
  REACT_APP_BACKEND_API_PROTOCOL,
  REACT_APP_BACKEND_API_URL,
  REACT_APP_BACKEND_API_PORT,
} from '../config.js';
import UserService from '../services/UserService';

const handleLogout = () => {
  // const kc = getKeycloakInstance();
  UserService.getKeycloakInstance().logout({ redirectUri: REACT_APP_KEYCLOAK_LOGOUT_URL });
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    height: '100vh',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    position: 'sticky',
    top: 0,
    paddingTop: 15,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    justifyContent: 'space-between',
    // textAlign: 'center',
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    color: '#AE5730',
    fontSize: '1rem',
    zIndex: 1000,
    textAlign: 'right',
    paddingBottom: 10,
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
  logout: {
    justifyContent: 'center',
    // textAlign: 'right',
    paddingBottom: 10,
  },
  logo: {
    height: '120px',
    paddingRight: '40px',
  },
  logoSmall: {
    height: '70px',
    paddingRight: '30px',
  },
  appName: {
    height: '60px',
  },
  appNameSmall: {
    height: '40px',
  },
}));

interface HomeProps {
  children: React.ReactNode;
  headerSmall: boolean;
}

const Home: React.FC<HomeProps> = ({ children, headerSmall }) => {
  const { t } = useTranslation();

  const [appVersionFE, setAppVersionFE] = useState('n.a.');
  const [appVersionBE, setAppVersionBE] = useState('n.a.');
  const [givenName] = useState(UserService.getFirstName());
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchVersion() {
      try {
        const response = await axios.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/info/app-version`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.data;
        setAppVersionBE(data.appVersion);
        setAppVersionFE(packageJson.version);
      } catch (error) {
        console.error('Error sending text to API:', error);
      }
    }

    fetchVersion();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <img src={logo} alt="Logo" className={headerSmall ? classes.logoSmall : classes.logo} onClick={() => navigate('/')} />
        {/* <img src={logo} alt="Logo" className={ headerSmall ? classes.logoSmall : classes.logo} onClick={ useNavigate().navigate('/')} /> */}
        <img src={appName} alt="App Name" className={headerSmall ? classes.appNameSmall : classes.appName} />
        <Button className={classes.logout} variant='outlined' onClick={handleLogout}>{t('header.logout_label')} - {givenName}</Button>
      </div>
      {/* <main className={classes.logout}>
      </main> */}
      <main className={classes.content}>
        {children}
      </main>
      <footer className={classes.footer}>
        @2024 BoCC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Typography variant='caption' color='primary'>{appVersionFE}</Typography> <Typography variant='caption' color='danger'>|</Typography> <Typography variant='caption' color='secondary'>{appVersionBE}</Typography>
      </footer>
    </div>
  );
};

export default Home;