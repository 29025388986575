import Keycloak from "keycloak-js";

import {
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_CLIENT_ID} from '../config.js';
  
  // let keycloakInstance;
  
const keycloakConfig = {
  realm: REACT_APP_KEYCLOAK_REALM,
  url: REACT_APP_KEYCLOAK_URL,
  clientId: REACT_APP_KEYCLOAK_CLIENT_ID
};

const _kc = new Keycloak(keycloakConfig);

let userActive = false;
const activityEvents = ['mousemove', 'keydown', 'click'];

const setUserActive = () => {
  userActive = true;
  setTimeout(() => userActive = false, 10000); // Reset activity status after 1 minute
};

activityEvents.forEach(event => {
  window.addEventListener(event, setUserActive);
});

// Function to refresh token
const refreshToken = () => {
  // console.log('Refreshing token - userActive: ' + userActive);
  console.log('Token: ' + _kc.token);
  if (userActive) {
    _kc.updateToken(30).then(refreshed => {
      if (refreshed) {
        console.log('Token refreshed');
      } else {
        if (_kc.tokenParsed ) {
          if (_kc.tokenParsed && _kc.tokenParsed.exp) {
            console.warn('Token not refreshed, valid for ' + Math.round(_kc.tokenParsed.exp - new Date().getTime() / 1000) + ' seconds');
          } else {
            console.warn('Token not refreshed, tokenParsed or tokenParsed.exp is undefined');
          }
        } else {
          console.warn('Token not refreshed and tokenParsed is undefined');
        }
      }
    }).catch(() => {
      console.error('Failed to refresh token');
    });
  } else {
    if (_kc.tokenParsed && _kc.tokenParsed.exp) {
      console.warn('Token not refreshed, valid for ' + Math.round(_kc.tokenParsed.exp - new Date().getTime() / 1000) + ' seconds');
    }
        // console.warn('Not refreshing token - user is inactive');
  }
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: any) => {
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        // doLogin();
        console.log("user is not authenticated..!");
      }
      setInterval(refreshToken, 10000);
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const getKeycloakInstance = () => _kc;

const doLogin = _kc.login;

const doLogout = () =>  {_kc.logout({ redirectUri: ''});};

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: any) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getFirstName = () => _kc.tokenParsed?.given_name;

const getLastName = () => _kc.tokenParsed?.family_name;

const hasRole = (roles: any) => roles.some((role: any) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getFirstName,
  getLastName,
  hasRole,
  getKeycloakInstance,
};

export default UserService;
