import React from 'react';
import logo from '../images/logo.svg';
import { Button, Typography } from '@mui/material';
import UserService from '../services/UserService';


const NotAuthenticated: React.FC = (props) => {

  return (
    <div>
      <img src={logo} alt="logo" style={{height: '230px', paddingBottom: '30px'}}/>
      <Typography variant="h4" style={{paddingBottom: '30px'}}>Sessione scaduta</Typography>
      <Button size='large' color='warning' variant='outlined' onClick={() => {UserService.doLogin()}}>Login</Button>
    </div>
  );
};

export default NotAuthenticated;