import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEnglish from "./locales/en/translation.json";
import translationItalian from "./locales/it/translation.json";

const resources = {
    en: {
        nativeName: 'English',
        translation: translationEnglish
    },
    it: {
        nativeName: 'Italian',
        translation: translationItalian
    }
}

i18next
.use(LanguageDetector)
.use(initReactI18next)
.init({
    debug: true,
    fallbackLng: 'en',
    // interpolation: {
    //   escapeValue: false, // not needed for react as it escapes by default
    // },
    resources,
    // lng: "en",
});

export default i18next;