import { ThemeProvider } from "@mui/material";
import { appTheme } from "./theme/theme";
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RenderOnAuthenticated from './components/keycloak/RenderOnAuthenticatedComponent';
import RenderOnAnonymous from './components/keycloak/RenderOnAnonymousComponent';
import NotAuthenticated from './components/NotAuthenticated';
import Home from './components/Home';
import VideoTable from './components/VideoTable';
import QuestionAndAnswer from "./components/QuestionAndAnswer";


function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <BrowserRouter>

        <div className="App">
          <header className="App-header">
            <RenderOnAnonymous>
              <NotAuthenticated />
            </RenderOnAnonymous>
            <RenderOnAuthenticated>
              <Routes>
                <Route path="/" element={<Home headerSmall={false}><VideoTable /></Home>} />
                <Route path="/qa/:media_id" element={<Home headerSmall={true}><QuestionAndAnswer /></Home>} />
              </Routes>
            </RenderOnAuthenticated>
          </header>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
