import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import {
  REACT_APP_BACKEND_API_PROTOCOL,
  REACT_APP_BACKEND_API_URL,
  REACT_APP_BACKEND_API_PORT,
} from '../config.js';

const useStyles = makeStyles({
  dropArea: {
    width: '99.7%',
    height: '80px',
    border: '2px dashed #ccc',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#aaa',
    cursor: 'pointer'
  },
  active: {
    borderColor: '#AE5730',
    color: '#AE5730',
    backgroundColor: '#666',
  },
});

interface DropAreaProps {
  updateVideoTable: () => void;
}

const DropArea: React.FC<DropAreaProps> = ({ updateVideoTable }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  // const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [fileInput, setFileInput] = useState<HTMLInputElement | null>(null);

  const handleDrop = useCallback(async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      console.log('files:', files[0]);
      await uploadViaApiFunction(files, updateVideoTable);

      // setFile(files[0]);
    }
    setIsDragging(false);
  }, [updateVideoTable]);

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleClick = () => {
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      await uploadViaApiFunction(files, updateVideoTable);
      console.log('files:', file);
      // Handle the selected file
    }
  }, [updateVideoTable]);

  return (
    <div
      className={`${classes.dropArea} ${isDragging ? classes.active : ''}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onClick={handleClick}
    // onClick={handleUpload}
    >
      {t('droparea.placeholder')}
      <input
        type="file"
        accept='video/*, .pdf'
        style={{ display: 'none' }}
        ref={input => setFileInput(input)}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default DropArea;

async function uploadViaApiFunction(files: FileList, updateVideoTable: () => void) {
  if (files[0].type === 'audio/aac' ||
    files[0].type === 'video/x-msvideo' ||
    files[0].type === 'audio/midi' ||
    files[0].type === 'audio/x-midi' ||
    files[0].type === 'audio/mpeg' ||
    files[0].type === 'video/mp4' ||
    files[0].type === 'video/mpeg' ||
    files[0].type === 'audio/wav' ||
    files[0].type === 'audio/webm' ||
    files[0].type === 'video/webm') {
    const formData = new FormData();
    formData.append('video', files[0]);

    try {
      const response = await axios.post(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response);
      updateVideoTable();
    } catch (error) {
      console.error('Error uploading video file:', error);
    }
  } else if (files[0].type === 'application/pdf') {
    const formData = new FormData();
    formData.append('pdf-file', files[0]);

    try {
      const response = await axios.post(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/pdf/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response);
      updateVideoTable();
    } catch (error) {
      console.error('Error uploading pdf file:', error);
    }
  } else {
    console.log('File non valido');
  }
}
