import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FormControlLabel, Checkbox, TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Tooltip, IconButton, Modal, Box, Typography, Autocomplete, TextField } from '@mui/material';
import { StorageOutlined, QuestionAnswerOutlined, SummarizeOutlined, BlockOutlined, FileDownloadOutlined, DeleteOutlined, TranscribeOutlined, CheckOutlined, CloseOutlined, HourglassEmptyOutlined, TranslateOutlined } from '@mui/icons-material';
import DropArea from './DropArea';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  REACT_APP_BACKEND_API_PROTOCOL,
  REACT_APP_BACKEND_API_URL,
  REACT_APP_BACKEND_API_PORT,
} from '../config.js';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface Data {
  id: number;
  file_name: string;
  transcribe_status: string;
  translations: string[];
  summaries: string[];
  original_language: string;
  file_type: string;
}

const VideoTable: React.FC = () => {
  const { t } = useTranslation();

  const options = [
    { key: 'ar-AE', value: t('shared.language.ar-AE') },
    { key: 'ar-SA', value: t('shared.language.ar-SA') },
    { key: 'en-US', value: t('shared.language.en-US') },
    { key: 'en-GB', value: t('shared.language.en-GB') },
    { key: 'it-IT', value: t('shared.language.it-IT') },
    { key: 'fr-FR', value: t('shared.language.fr-FR') },
    { key: 'es-ES', value: t('shared.language.es-ES') },
  ];

  const [data, setData] = useState<Data[]>([]);
  const [availableLanguages, setAvailableLanguages] = useState<string[]>([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [correctText, setCorrectText] = React.useState(true);

  const [modalState, setModalState] = useState({
    isTranslateModalOpen: false,
    isTranscribeModalOpen: false,
    isSummarizeModalOpen: false,
    currentId: null as number | null,
    fileType: null as string | null,
    selectedOption: '',
    transcribeSelectedOption: '',
    summarizeSelectedOption: '',
  });

  const navigate = useNavigate();

  const handleNavigateToChat = (row_id: number) => {
    navigate(`/qa/${row_id}`);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/all`);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const getTranslationLanguages = async () => {
  //   try {
  //     const response = await axios.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/mapping/language/translate/<current_language>`);
  //     console.log(response.data.availableLanguage);
  //   } catch (error) {
  //     console.error('Error fetching languages:', error);
  //   }
  // };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 10000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const handleApiCall = async (url: string, closeModal: () => void) => {
    for (let i = 0; i <= 5; i++) {
      setTimeout(() => {
        fetchData();
      }, 500);
    }
    try {
      closeModal();
      const payload = {
        use_correct_text: correctText,
      };
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.post(url, payload, config);
      console.log('API response:', response.data);
      fetchData();
    } catch (error) {
      console.error('Error in API call:', error);
    }
  };

  const handleModalOpen = async (id: number, fileType: string | null, modalType: string) => {
    try {
      if (modalType === 'isTranslateModalOpen') {
        const response = await axios.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/mapping/language/translate/${id}`);
        setAvailableLanguages(response.data.availableLanguage);
      } else if (modalType === 'isSummarizeModalOpen') {
        const response = await axios.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/mapping/language/summarize/${id}`);
        console.log('cazzo');
        console.log(response.data.availableLanguage);
        setAvailableLanguages(response.data.availableLanguage);
        console.log('cazzo-2');
        console.log(availableLanguages);
      } else if (modalType === 'isTranscribeModalOpen') {
        setAvailableLanguages(options.map(option => option.key));
      }
      // console.log(response.data.availableLanguage);
      // setAvailableLanguages(response.data.availableLanguage);
      // console.log(availableLanguages.includes('it-IT'));
    } catch (error) {
      console.error('Error fetching languages:', error);
    }

    setModalState(prevState => ({
      ...prevState,
      currentId: id,
      fileType,
      [modalType]: true,
    }));
  };

  const handleModalClose = (modalType: string) => {
    setModalState(prevState => ({
      ...prevState,
      [modalType]: false,
    }));
  };

  const downloadFile = async (url: string, fileName: string) => {
    try {
      const response = await axios.get(url);
      const blob = new Blob([response.data.result.result], { type: 'text/plain' });
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlBlob;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <>
      <Modal open={modalState.isTranslateModalOpen} onClose={() => handleModalClose('isTranslateModalOpen')}>
        <Box sx={style}>
          <Typography color='primary' id="modal-modal-title" variant="h6" >
            {t('modal.translate.title')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.value}
              getOptionDisabled={(option) => !availableLanguages.includes(option.key)}
              renderInput={(params) => <TextField {...params} label={t('modal.translate.label')} variant="outlined" />}
              onChange={(event, newValue) => {
                setModalState(prevState => ({ ...prevState, selectedOption: newValue?.key || '' }));
              }}
            />
          </Typography>
          <br />
          <Button variant='contained' style={{ marginRight: '10px' }}
            disabled={modalState.selectedOption === ''}
            onClick={() => handleApiCall(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/text/translate/${modalState.currentId}/${modalState.selectedOption}`, () => handleModalClose('isTranslateModalOpen'))}
          >
            {t('modal.translate.button')}
          </Button>
          <Button variant='outlined' color='error'
            onClick={() => handleModalClose('isTranslateModalOpen')}
          >
            {t('modal.translate.cancel')}
          </Button>
        </Box>
      </Modal>
      <Modal open={modalState.isTranscribeModalOpen} onClose={() => handleModalClose('isTranscribeModalOpen')}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" color='primary'>
            {t('modal.transcribe.title')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.value}
              getOptionDisabled={(option) => !availableLanguages.includes(option.key)}
              renderInput={(params) => <TextField {...params} label={t('modal.transcribe.label')} variant="outlined" />}
              onChange={(event, newValue) => {
                setModalState(prevState => ({ ...prevState, transcribeSelectedOption: newValue?.key || '' }));
              }}
            />
          </Typography>
          <br />
          <FormControlLabel label={<Typography color='primary' variant='subtitle1'>{t('modal.transcribe.correctText')}</Typography>} control={<Checkbox checked={correctText} onChange={() => setCorrectText(prevState => !prevState)} size="small" defaultChecked />} />
          <br />
          <br />
          <Button variant='contained' style={{ marginRight: '10px' }}
            disabled={modalState.transcribeSelectedOption === ''}
            onClick={() => handleApiCall(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/${modalState.fileType}/transcribe/${modalState.currentId}/${modalState.transcribeSelectedOption}`, () => handleModalClose('isTranscribeModalOpen'))}
          >
            {t('modal.transcribe.button')}
          </Button>
          <Button variant='outlined' color='error'
            onClick={() => handleModalClose('isTranscribeModalOpen')}
          >
            {t('modal.transcribe.cancel')}
          </Button>
        </Box>
      </Modal>
      <Modal open={modalState.isSummarizeModalOpen} onClose={() => handleModalClose('isSummarizeModalOpen')}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" color='primary'>
            {t('modal.summary.title')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.value}
              getOptionDisabled={(option) => !availableLanguages.includes(option.key)}
              renderInput={(params) => <TextField {...params} label={t('modal.summary.label')} variant="outlined" />}
              onChange={(event, newValue) => {
                setModalState(prevState => ({ ...prevState, summarizeSelectedOption: newValue?.key || '' }));
              }}
            />
          </Typography>
          <br />
          <Button variant='contained' style={{ marginRight: '10px' }}
            disabled={modalState.summarizeSelectedOption === ''}
            onClick={() => handleApiCall(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/text/summarize/${modalState.currentId}/${modalState.summarizeSelectedOption}`, () => handleModalClose('isSummarizeModalOpen'))}
          >
            {t('modal.summary.button')}
          </Button>
          <Button variant='outlined' color='error'
            onClick={() => handleModalClose('isSummarizeModalOpen')}
          >
            {t('modal.summary.cancel')}
          </Button>
        </Box>
      </Modal>
      <DropArea updateVideoTable={fetchData} />
      <div style={{ height: '10px' }}> </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('table.header.id')}</TableCell>
              <TableCell>{t('table.header.video_name')}</TableCell>
              <TableCell>{t('table.header.document_status')}</TableCell>
              <TableCell>{t('table.header.original_language')}</TableCell>
              <TableCell style={{ minWidth: '160px' }}>{t('table.header.actions')}</TableCell>
              <TableCell>{t('table.header.available_translations')}</TableCell>
              {/* <TableCell>{t('table.header.summary_available')}</TableCell> */}
              <TableCell>{t('table.header.extended_summary_available')}</TableCell>
              <TableCell>{t('table.header.qa')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.file_name}</TableCell>
                <TableCell>
                  {row.transcribe_status === 'COMPLETED' && <span className='text-icon-center'>{t('table.values.transcription_available')}&nbsp;<CheckOutlined color='primary' /></span>}
                  {row.transcribe_status === 'UPLOADED' && <span className='text-icon-center'>{t('table.values.uplaoded')}<CloseOutlined color='secondary' /></span>}
                  {row.transcribe_status === 'TRANSCRIBING' && <span className='text-icon-center'>{t('table.values.working_trascription')}<HourglassEmptyOutlined style={{ color: 'orange' }} /></span>}
                  {row.transcribe_status === 'TRANSLATING' && <span className='text-icon-center'>{t('table.values.working_translation')}<HourglassEmptyOutlined style={{ color: 'orange' }} /></span>}
                  {row.transcribe_status === 'SUMMARIZING' && <span className='text-icon-center'>{t('table.values.working_summary')}<HourglassEmptyOutlined style={{ color: 'orange' }} /></span>}
                  {row.transcribe_status === 'ADD_TO_RAG' && <span className='text-icon-center'>{t('table.values.working_add_to_rag')}<StorageOutlined style={{ color: 'orange' }} /></span>}
                </TableCell>
                <TableCell>
                  <Tooltip title={t('table.tooltip.download_original_language')}>
                    <span>
                      <Button
                        disabled={row.transcribe_status === 'UPLOADED' || row.transcribe_status === 'TRANSCRIBING'}
                        onClick={() => downloadFile(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/get/${row.id}`, `${row.file_name}-transcript.txt`)}
                      >
                        {row.original_language ? <span>{row.original_language}&nbsp;<FileDownloadOutlined /></span> : <BlockOutlined />}
                      </Button>
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={t('table.tooltip.transcribe')}>
                    <span>
                      <IconButton
                        disabled={['COMPLETED', 'TRANSCRIBING', 'TRANSLATING', 'SUMMARIZING'].includes(row.transcribe_status)}
                        onClick={() => handleModalOpen(row.id, row.file_type === 'application/pdf' ? 'pdf' : 'video', 'isTranscribeModalOpen')}
                        style={{ color: ['COMPLETED', 'TRANSCRIBING', 'TRANSLATING', 'SUMMARIZING'].includes(row.transcribe_status) ? '' : 'orange' }}
                      >
                        <TranscribeOutlined />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t('table.tooltip.translate')}>
                    <span>
                      <IconButton
                        disabled={row.transcribe_status !== 'COMPLETED'}
                        onClick={() => handleModalOpen(row.id, null, 'isTranslateModalOpen')}
                        color='info'
                      >
                        <TranslateOutlined />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t('table.tooltip.summary')}>
                    <span>
                      <IconButton
                        disabled={row.transcribe_status !== 'COMPLETED'}
                        onClick={() => handleModalOpen(row.id, null, 'isSummarizeModalOpen')}
                        color='success'
                      >
                        <SummarizeOutlined />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t('table.tooltip.delete')}>
                    <IconButton
                      disabled={['TRANSCRIBING', 'TRANSLATING', 'SUMMARIZING'].includes(row.transcribe_status)}
                      onClick={() => handleApiCall(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/delete/${row.id}`, () => { })}
                      color='error'
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {row.translations.map((translation) =>
                    <Tooltip title={t('shared.language.' + translation)} key={translation}>
                      <Button size='small' onClick={() => downloadFile(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/get/translation/${row.id}/${translation}`, `${row.file_name}-transcript-${translation}.txt`)}>
                        {translation}
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                {/* <TableCell>
                  {row.summaries.map((summary) =>
                    <Tooltip title={t('shared.language.' + summary)} key={summary}>
                      <Button size='small' onClick={() => downloadFile(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/get/summary/${row.id}/${summary}`, `${row.file_name}-summary-${summary}.txt`)}>
                        {summary}
                      </Button>
                    </Tooltip>
                  )}
                </TableCell> */}
                <TableCell>
                  {row.summaries.map((summary) =>
                    <Tooltip title={t('shared.language.' + summary)} key={summary}>
                      <Button size='small' onClick={() => downloadFile(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/get/summary/extended/${row.id}/${summary}`, `${row.file_name}-summary-extended-${summary}.txt`)}>
                        {summary}
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title={t('table.tooltip.chat')}>
                    <span>
                      <IconButton
                        disabled={row.transcribe_status !== 'COMPLETED'}
                        onClick={() => { handleNavigateToChat(row.id) }}
                        color='info'
                      >
                        <QuestionAnswerOutlined />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </TableContainer>
    </>
  );
};

export default VideoTable;