import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Box, Button, TextField, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import UserService from '../services/UserService';
import { useNavigate } from 'react-router-dom';

import {
  REACT_APP_BACKEND_API_PROTOCOL,
  REACT_APP_BACKEND_API_URL,
  REACT_APP_BACKEND_API_PORT,
} from '../config.js';
import LoadSpinnerChat from './util/LoadSpinnerChat';


const useStyles = makeStyles(() => ({
  questionCard: {
    display: 'flex',
    justifyContent: 'flex-end',
    // width: '70%',
    backgroundColor: useTheme().palette.primary.main + " !important",
    borderRadius: '15px !important',
    marginBottom: '10px',
    marginLeft: '30%'
  },
  answerCard: {
    display: 'flex',
    justifyContent: 'flex-start',
    // width: '80%',
    textAlign: 'left',
    backgroundColor: useTheme().palette.secondary.dark + " !important",
    borderRadius: '15px !important',
    marginBottom: '30px',
    marginRight: '20%'
  },
  typewriter: {
    display: 'flex',
    justifyContent: 'flex-start',
    // width: '80%',
    textAlign: 'left',
    backgroundColor: useTheme().palette.grey + " !important",
    color: useTheme().palette.secondary.light + " !important",
    borderRadius: '15px !important',
    marginBottom: '30px',
    marginRight: '20%'
  }
}));

const QuestionAndAnswer: React.FC = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const { media_id } = useParams();
  const [modelVersion, setModelVersion] = useState('');
  const [inputText, setInputText] = useState('');
  const [filename, setFilename] = useState('');

  const [receivedMessages, setReceivedMessages] = useState<string[]>([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [newMessage, setNewMessage] = useState<string | null>(null);

  const [stopScrollToBottomEffect, setStopScrollToBottomEffect] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const currentIndex = useRef(0);
  const typeWriter = useRef<NodeJS.Timeout | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const speed = 10;

  useEffect(() => {
    async function fetchVersion() {
      try {
        const axiosInstance = axios.create({
          baseURL: `${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/`,
          withCredentials: true // This ensures cookies are sent with requests
        });
        const response = await axiosInstance.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/info/ai-model`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.data;
        setModelVersion(data.aiModel);

        const response_name = await axiosInstance.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/video/get/filename/${media_id}`, {
          headers: {
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        const filename = await response_name.data.filename;
        setFilename(filename);
        console.log(filename);

        const chat_history = await axiosInstance.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/rag/chat/restore/${media_id}`, {
          headers: {
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        console.log(chat_history.data);
        for (const msg of chat_history.data.chat_history) {
          if (msg.question) {
            console.log(msg.question);
            setReceivedMessages((prevMessages) => [...prevMessages, '__question__ ' + msg.question]);
          } else if (msg.answer) {
            console.log(msg.answer);
            setReceivedMessages((prevMessages) => [...prevMessages, msg.answer]);
          }
        };
        // const filename = await response_name.data.filename;

      } catch (error) {
        console.error('Error sending text to API:', error);
      }
    }

    fetchVersion();
  }, [media_id]);

  useEffect(() => {
    if (newMessage) {
      var currentReceivedMessage = newMessage;
      currentIndex.current = 0;
      typeWriter.current = setInterval(() => {
        if (currentIndex.current < currentReceivedMessage.length) {
          setStopScrollToBottomEffect(false);
          setCurrentMessage(currentReceivedMessage.substring(0, currentIndex.current));
          // setCurrentMessage(prev => prev + currentReceivedMessage[currentIndex.current - 1]);
          currentIndex.current++;
        } else {
          clearInterval(typeWriter.current as NodeJS.Timeout);
          setStopScrollToBottomEffect(true);
          if (newMessage) {
            setReceivedMessages((prevMessages) => [...prevMessages, newMessage]);
            setNewMessage(null);
            setCurrentMessage('');
          }
        }
      }, speed);
    }
    return () => clearInterval(typeWriter.current as NodeJS.Timeout);
  }, [newMessage]);

  useEffect(() => {
    if (stopScrollToBottomEffect) {
      return;
    }

    const interval = setInterval(() => {
      scrollToBottom();
    }, 200); // 1000 milliseconds = 1 second

    // Clear interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [stopScrollToBottomEffect]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto', inline: 'end' })
    // console.log('Scrolling to bottom');
  }

  const downloadFile = async () => {
    try {
      const axiosInstance = axios.create({
        baseURL: `${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/`,
        withCredentials: true // This ensures cookies are sent with requests
      });
      const response = await axiosInstance.get(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/rag/chat/download/${media_id}`, {
        headers: {
          'Authorization': `Bearer ${UserService.getToken()}`
        },
        responseType: 'arraybuffer' // Ensure the response is treated as binary data
      });
      console.log(response);
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument' });
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlBlob;
      a.download = 'chat_history-' + media_id + '.docx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const sendTextToAPI = async () => {
    try {
      setNewMessage('__question__ ' + inputText);
      setInputText('');
      setShowLoader(true);
      const axiosInstance = axios.create({
        baseURL: `${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/`,
        withCredentials: true // This ensures cookies are sent with requests
      });
      const response = await axiosInstance.post(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/rag/query/${media_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({ 'query': inputText }),
        }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${UserService.getToken()}`
        },
      });
      const data = await response.data;
      setShowLoader(false);
      setNewMessage(data.response);
    } catch (error) {
      console.error('Error sending text to API:', error);
    }
  };

  const clearChatHistory = async () => {
    // const kc = getKeycloakInstance();
    try {
      const axiosInstance = axios.create({
        baseURL: `${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/`,
        withCredentials: true // This ensures cookies are sent with requests
      });
      const response = await axiosInstance.post(`${REACT_APP_BACKEND_API_PROTOCOL}://${REACT_APP_BACKEND_API_URL}:${REACT_APP_BACKEND_API_PORT}/api/chat/history/delete/${media_id}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${UserService.getToken()}`
          },
        },
      );
      const data = await response.data;
      setReceivedMessages([]);
      console.log(data); // Handle the response data
    } catch (error) {
      console.error('Error sending static payload to API:', error);
    }
  };

  return (
    <div>
      <div className="input-box" style={{ backgroundColor: '#1e1e1e', position: 'sticky', top: 0 }}>
        <Box
          sx={{
            '& > :not(style)': { m: 1 }, // Apply margin to all children except style elements
            display: 'flex', // Use flexbox for layout
            flexDirection: 'column', // Stack children vertically
          }}
        >
          <Box
            sx={{
              display: 'flex', // Use flexbox for layout
              flexGrow: 1, // Allow the box to expand as much as possible
              justifyContent: 'end', // Center items horizontally
              alignItems: 'end', // Align items vertically in the center
            }}
          >
            <Typography variant='caption'>AI Model:</Typography>&nbsp;&nbsp;<Typography color='secondary'><b>Claude3 [ {modelVersion} ]</b></Typography>
          </Box>
          <Box className="input-box-field"
            sx={{
              display: 'flex', // Use flexbox for layout
              flexDirection: 'row', // Place children in a row
              alignItems: 'center', // Align items vertically in the center
            }}
          >
            <TextField
              type="text"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder={(t('qa.question_placeholder'))}
              variant='standard'
              fullWidth
              multiline
              rows={2}
              style={{ paddingRight: '30px' }}
              onKeyDown={(e) => {
                if ((e.key === 'Enter' && e.ctrlKey) || e.key === 'Enter') {
                  e.preventDefault(); // Prevent the default action to avoid submitting the form or any other unwanted behavior
                  sendTextToAPI(); // Call the function to send the text to the API
                }
              }}
            />
            <Button disabled={inputText === ''} variant='outlined' onClick={sendTextToAPI}>{(t('qa.send_button'))}</Button>
            <span style={{ width: '10px' }}></span>
            <Button variant='outlined' onClick={clearChatHistory}>{(t('qa.reset_button'))}</Button>
          </Box>
        </Box>
        <Box className="input-box-field" sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center', paddingRight: '10px', paddingBottom: '7px' }}>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'start', alignItems: 'center', paddingRight: '10px' }}>
            <Typography style={{ paddingLeft: '10px' }} variant='body2'>{t('qa.document_name')}:</Typography>
            <Typography style={{ paddingLeft: '10px' }} color='primary'>{filename}</Typography>
          </Box>
          <Button variant='outlined' onClick={() => navigate('/')}>{(t('qa.home'))}</Button>
          <span style={{ width: '10px' }}></span>
          <Button variant='outlined' onClick={() => downloadFile()}>{(t('qa.download_chat'))}</Button>
        </Box>
      </div>
      <div className="chat-box">
        {receivedMessages.map((msg: string, index: number) => (
          msg.startsWith('__question__ ') ? (
            <Card className={classes.questionCard} variant='outlined' key={`question-${index}`} >
              <CardContent>
                <Typography variant='inherit'>{msg.replace('__question__ ', '')}</Typography>
                {/* <p dangerouslySetInnerHTML={{ __html: msg.replace('__question__ ', '') }} /> */}
              </CardContent>
            </Card>
          ) : (
            <Card className={classes.answerCard} variant='outlined' key={`response-${index}`} >
              <CardContent>
                <Typography variant='inherit'>

                  <p dangerouslySetInnerHTML={{ __html: msg }} />
                </Typography>
              </CardContent>
            </Card>
          )
          // msg.startsWith('__question__ ') ? (
          //   <div style={{ display: 'flex', justifyContent: 'flex-end' }} key={`question-${index}`}>
          //     <p className='request-box' key={index} dangerouslySetInnerHTML={{ __html: msg.replace('__question__ ', '') }} />
          //   </div>
          // ) : (
          //   <div key={`question-${index}`}>
          //     <p className='response-box' key={index} dangerouslySetInnerHTML={{ __html: msg }} />
          //   </div>
          // )
        ))}
        {currentMessage !== '' &&
          <Card className={classes.answerCard} variant='outlined'>
            <CardContent>
              <p dangerouslySetInnerHTML={{ __html: currentMessage.replace('__question__ ', '') }} />
            </CardContent>
          </Card>
        }
      </div>
      <Box
        sx={{
          display: 'flex', // Use flexbox for layout
          flexGrow: 1, // Allow the box to expand as much as possible
          justifyContent: 'center', // Center items horizontally
          alignItems: 'center', // Align items vertically in the center
        }}
      >
        {showLoader ? <LoadSpinnerChat /> : null}
      </Box>
      <div ref={messagesEndRef} ></div>
    </div>
  );
};

export default QuestionAndAnswer;